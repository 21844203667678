import logo from "../../images/logo.png";
import setting from "../../images/menu_settings.png";
import { PageState } from "../../App";

import "./topBar.sass";

interface Props {
	setPageState: React.Dispatch<React.SetStateAction<PageState>>;
}

export function TopBar(props: Props) {
	const handleSettings = () => props.setPageState(PageState.settings);

	return (
		<div id={"TopBar"}>
			<img id={"LogoTV5"} src={logo} alt="TV5 Monde Plus logo" />
			<img
				id={"ImageSettings"}
				src={setting}
				alt="Settings menu"
				onClick={handleSettings}
			/>
		</div>
	);
}
