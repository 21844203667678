import { ClientInformation } from "./clientRegistration";
import { formBody } from "../../tools/formBody";
import { BASE_URL } from "../CONSTANTS_ADOBE";

export interface AccessToken {
	token_type: string;
	access_token: string;
}

function parseRes(json: any) {
	return {
		token_type: json.token_type,
		access_token: json.access_token,
	};
}

export async function accessToken(clientInformation: ClientInformation) {
	const url = new URL(BASE_URL + "/o/client/token");
	const params: RequestInit = {
		method: "post",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		body: formBody({
			client_id: clientInformation.client_id,
			client_secret: clientInformation.client_secret,
			grant_type: clientInformation.grant_type,
		}),
	};

	const res = await fetch(url.href, params);
	if (!res.ok) throw res;
	return parseRes(await res.json());
}
