import { BASE_URL, DOMAIN_NAME, REQUESTOR } from '../CONSTANTS_ADOBE';

import { AccessToken } from "./accessToken";

export interface Mvpd {
	id: string;
	displayName: string;
	logoUrl: string;
}

function parseRes(json: any) {
	return json;
}

export async function initiateAuthentication(
	msoId: string,
	regCode: string,
	token: AccessToken
) {
	const url = new URL(BASE_URL + "/api/v1/authenticate");
	const headers = {
		Authorization: `${token.token_type} ${token.access_token}`,
	};
	const params = {
		requestor_id: REQUESTOR,
		mso_id: msoId,
		reg_code: regCode,
		domain_name: DOMAIN_NAME,
		noflash: "true",
		no_iframe: "true",
		redirect_url: "https://tv5mondeplus.com",
	};

	url.search = new URLSearchParams(params).toString();
	window.location.href = url.href;
	// const res = await fetch(url.href, { headers: headers });
	// if (!res.ok) throw res;
	// return parseRes(await res.text());
	return null;
}
