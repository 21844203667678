/**
 ** Format Json to x-www-form-urlencoded
 */
export function formBody(json: any) {
	const formBody = [];

	for (const property in json) {
		var encodedKey = encodeURIComponent(property);
		var encodedValue = encodeURIComponent(json[property]);
		formBody.push(encodedKey + "=" + encodedValue);
	}
	return formBody.join("&");
}
