import { useState } from "react";
import { useEffect } from "react";

import { AccessToken } from "./services/adobe/callApi/accessToken";

import { ProviderListPage } from "./pages/providersList/providersListPage";
import { Loader } from "./componants/loader/loader";
import { getToken } from "./services/adobe/callflow/registration";
import { Mvpd } from "./services/adobe/callApi/initiateAuthentication";
import { TopBar } from "./componants/topBar/topBar";
import { ActivationPage } from "./pages/activation/activationPage";
import { provideMvpdList } from "./services/adobe/callApi/provideMvpdList";
import { SettingsPage } from "./pages/settings/settingsPage";

import EN from "./language/en.json";
import FR from "./language/fr.json";

import "./App.sass";

export enum PageState {
	activation = "activation",
	provider = "provider",
	settings = "settings",
}

export enum Language {
	en = "EN",
	fr = "FR",
}

export default function App() {
	const [textApp, setTextApp] = useState(EN);
	const [language, setLanguage] = useState(Language.en);
	const [isLoad, setIsLoad] = useState(false);
	const [mvpds, setMvpds] = useState<Mvpd[]>([]);
	const [token, setToken] = useState<AccessToken | null>(null);
	const [registrationCode, setRegistrationCode] = useState<string | null>(
		null
	);
	const [pageState, setPageState] = useState(PageState.activation);

	useEffect(() => {
		Promise.all([getToken(), provideMvpdList()]).then(
			(values) => {
				setToken(values[0]);
				setMvpds(values[1]);
				setIsLoad(true);
			},
			(error) => {
				console.error("[HOME] Error: ", error);
			}
		);
	}, []);

	useEffect(() => {
		switch (language) {
			case Language.en:
				setTextApp(EN);
				break;
			case Language.fr:
				setTextApp(FR);
				break;
		}
	}, [language]);

	const renderPage = () => {
		switch (pageState) {
			case PageState.activation:
				return (
					<ActivationPage
						textApp={textApp}
						setRegistrationCode={setRegistrationCode}
						setPageState={setPageState}
					/>
				);
			case PageState.provider:
				return (
					<ProviderListPage
						textApp={textApp}
						mvpds={mvpds}
						token={token}
						registrationCode={registrationCode}
						setRegistrationCode={setRegistrationCode}
						setPageState={setPageState}
					/>
				);
			case PageState.settings:
				return (
					<SettingsPage
						textApp={textApp}
						setLanguage={setLanguage}
						setPageState={setPageState}
					/>
				);
			default:
				return null;
		}
	};

	const render = (
		<div id={"App"}>
			<TopBar setPageState={setPageState} />
			{renderPage()}
		</div>
	);

	return isLoad ? render : <Loader />;
}
