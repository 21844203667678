import arrow from "../../images/arrow.png";
import { Language, PageState } from "../../App";
import { TextApp } from "../../language/languageType";

import "./settingsPage.sass";

interface Props {
	textApp: TextApp;
	setLanguage: React.Dispatch<React.SetStateAction<Language>>;
	setPageState: React.Dispatch<React.SetStateAction<PageState>>;
}

export function SettingsPage(props: Props) {
	const listLanguage = [
		{ code: Language.en, name: "English" },
		{ code: Language.fr, name: "Français" },
	];

	const handleArrow = () => props.setPageState(PageState.activation);

	const handleLanguage = (language: Language) => {
		props.setLanguage(language);
		props.setPageState(PageState.activation);
	};

	return (
		<div id={"SettingsPage"}>
			<img
				id={"ArrowImage"}
				src={arrow}
				alt={"Back button"}
				onClick={handleArrow}
			></img>
			<div id={"LanguageList"}>
				<div id={"TitleLanguageList"}>
					{props.textApp.setting.title}
				</div>
				{listLanguage.map((language, index) => (
					<div
						className={"buttonLanguage"}
						key={index}
						onClick={() => handleLanguage(language.code)}
					>
						<div className={"textButtonLanguage"}>
							{language.name}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
