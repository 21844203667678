import { BASE_URL, REQUESTOR } from "../CONSTANTS_ADOBE";

export interface Mvpd {
	id: string;
	displayName: string;
	logoUrl: string;
}

function parseRes(json: any): Mvpd[] {
	const mvpds: Mvpd[] = [];

	json.requestor.mvpds.mvpd.forEach((mvpd: any) => {
		mvpds.push({
			id: mvpd.id.value,
			displayName: mvpd.displayName.value,
			logoUrl: mvpd.logoUrl.value,
		});
	});
	return mvpds;
}

export async function provideMvpdList() {
	const url = new URL(BASE_URL + `/api/v1/config/${REQUESTOR}?format=json`);

	const res = await fetch(url.href);
	if (!res.ok) throw res;
	return parseRes(await res.json());
}
