export const DOMAIN_NAME = "tv5mondeplus.com";

export const BASE_URL = 
// Pre-prod
	// "http://api.auth-staging.adobe.com";
// Prod
	"https://api.auth.adobe.com";

export const REQUESTOR = "TV5MONDE";

export const SOFTWARE_STATEMENT =
// Pre-prod
	// "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0MWRhZGRjYS01MTdkLTRjYmItYWVjMC1kN2FkZWM0MDk1N2YiLCJuYmYiOjE2MTY2MDY3MDIsImlzcyI6ImF1dGguYWRvYmUuY29tIiwiaWF0IjoxNjE2NjA2NzAyfQ.sTI9J9bAp90kCUdO4zn-t5vtelKMFwlsYLt6kroy4Duu7VnqDbE932W26EMzGglYWhTNwQZ8EqSH05kix5nxRzrfuT_nrhIm-_5LxxYw1upoNLeoW2jOIgr3BKbateYWRECKIiuGBbqbYwQkP4l0WQ18Ta-WGpANOgRYSut9YgrsBPFSXZqF-cTeokYTyC0jj85VBZ5ZC5PP0XOqUtJ8ZqvuujdfWgterD0EnZWcjQz9raHVGDE2oqwtmaC943M8TM3ZQS12HS-jAk7VLArjFM4EougisJ0yKgcjMx-HFA1AYun5SSHe85oTxNH2rMrczmC4HSXv8_01r9VeMvwjwg";
// Prod
	"eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIzMzA2MjVhMi1hZDJjLTRhMTAtOTdmNi05NjRlOWQ2MzRkNTUiLCJuYmYiOjE2Mjg2MDc5NzQsImlzcyI6ImF1dGguYWRvYmUuY29tIiwiaWF0IjoxNjI4NjA3OTc0fQ.UjItAUgoqd9iz6KCABBz0zfzjGshjJgIh8QKDYWN6LksZahVp2PojY8FEOFgkTZ9fEDwWAN6sz9NM5qRZ-_pMB_GkgT3XfhwC0BJPlejn4kyh3XuRV0nWcBweQ6cP1wJBnfsNJAEEMS-vsDXz9q2gTbbFDv0nbNNG0NvPANLhCt36PvNv90jVGcOIHjBAYDSaXa0WhCDVJa_0YrHo2UxEAmt28iFljCywvF2MH_tW80SWrtDlRc21zC4bKvkOHl6Qn-YFPpO4lrt9unY4ZDql9yE7Cqe75fMGW4IIghZYQOA5OjL7CVXrvK15IT-7JsZesVHuhNOfKs4Qgk85qGAKQ";