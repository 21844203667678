import { useState } from "react";
import arrow from "../../images/arrow.png";
import { PageState } from "../../App";
import { AccessToken } from "../../services/adobe/callApi/accessToken";
import {
	initiateAuthentication,
	Mvpd,
} from "../../services/adobe/callApi/initiateAuthentication";
import { TextApp } from "../../language/languageType";
import { PopUp } from "../../componants/popUp/popUp";

import "./providersListPage.sass";

interface Props {
	textApp: TextApp;
	mvpds: Mvpd[];
	registrationCode: string | null;
	token: AccessToken | null;
	setRegistrationCode: React.Dispatch<React.SetStateAction<string | null>>;
	setPageState: React.Dispatch<React.SetStateAction<PageState>>;
}

export function ProviderListPage(props: Props) {
	const { registrationCode, token, mvpds } = props;
	const [error, setError] = useState<string | null>(null);

	const handleClickProvider = async (mvpdId: string) => {
		if (registrationCode && token) {
			try {
				const html = await initiateAuthentication(
					mvpdId,
					registrationCode,
					token
				);
				// if (html.match(/document.forms\[0\].submit()/)) {
				// 	document.write(html);
				// 	document.forms[0].submit();
				// } else {
					// setError(props.textApp.error.registrationCode);
				// }
				console.log("<<< test activation with location");
			} catch (error) {
				setError(props.textApp.error.registrationCode);
			}
		} else {
			setError(props.textApp.error.registrationCode);
		}
	};

	const handleBackButton = () => {
		props.setRegistrationCode("");
		props.setPageState(PageState.activation);
	};

	return (
		<div id={"ProvidersListPage"}>
			{error ? <PopUp text={error} /> : null}
			<img
				id={"ArrowImage"}
				src={arrow}
				alt={"Back button"}
				onClick={handleBackButton}
			></img>
			<div className={"titleProvidersList"}>
				{props.textApp.providerList.title} {props.registrationCode}
			</div>
			<div className={"titleProvidersList"}>
				{props.textApp.providerList.detail}
			</div>
			<div id={"ProvidersList"}>
				{mvpds.map((mvpd) => {
					return (
						<div
							key={mvpd.id}
							className={"provider"}
							onClick={() => handleClickProvider(mvpd.id)}
						>
							<div className={"logoProvider"}>
								<img
									className={"imageProvider"}
									src={mvpd.logoUrl}
									alt={"Provider logo"}
								/>
							</div>
							<div className={"textProvider"}>
								{mvpd.displayName}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
