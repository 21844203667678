import { BASE_URL, SOFTWARE_STATEMENT } from '../CONSTANTS_ADOBE';

export interface ClientInformation {
	client_id: string;
	client_secret: string;
	grant_type: string;
}

function parseRes(json: any) {
	return {
		client_id: json.client_id,
		client_secret: json.client_secret,
		grant_type: json.grant_types?.length ? json.grant_types[0] : "",
	};
}

export async function clientRegistration() {
	const url = new URL(BASE_URL + "/o/client/register");
	const params: RequestInit = {
		method: "post",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			software_statement: SOFTWARE_STATEMENT,
		}),
	};

	const res = await fetch(url.href, params);
	if (!res.ok) throw res;
	return parseRes(await res.json());
}
