import { useState } from "react";
import { PageState } from "../../App";
import { TextApp } from "../../language/languageType";

import "./activationPage.sass";

interface Props {
	textApp: TextApp;
	setRegistrationCode: React.Dispatch<React.SetStateAction<string | null>>;
	setPageState: React.Dispatch<React.SetStateAction<PageState>>;
}

export function ActivationPage(props: Props) {
	const [code, setCode] = useState("");

	const handleChange = (event: any) => {
		if (event.target.value.length < 8) {
			setCode(event.target.value.toUpperCase());
		} else if (event.target.value.length > 7) {
			setCode(event.target.value.substring(0, 7).toUpperCase());
		}
	};

	const handleButton = () => {
		props.setRegistrationCode(code);
		props.setPageState(PageState.provider);
	};

	return (
		<div id={"ActivationPage"}>
			<div id={"TitleActivation"}>{props.textApp.activation.title}</div>
			<div className={"textActivation"}>
				{props.textApp.activation.detail}
			</div>
			<input
				id={"InputActivation"}
				type="text"
				value={code}
				onChange={handleChange}
				placeholder={props.textApp.activation.placeholder}
			/>
			<button
				id={"ButtonActivation"}
				style={{
					backgroundColor: code.length !== 7 ? "grey" : "#00b2a9",
				}}
				onClick={handleButton}
				disabled={code.length !== 7}
			>
				<div className={"textActivation"}>
					{props.textApp.activation.button}
				</div>
			</button>
		</div>
	);
}
