import "./popUp.sass";

interface Props {
	text: string;
}

export function PopUp(props: Props) {
	const handleClick = () => {
		document.location.reload();
	};

	return (
		<div id={"PopUp"}>
			<div id={"FramePopUp"}>
				<div id={"TitlePopUp"}>{props.text}</div>

				<button id={"ButtonPopUp"} onClick={handleClick}>
					<div id={"TextButtonPopUp"}>Ok</div>
				</button>
			</div>
		</div>
	);
}
