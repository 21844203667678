import "./loader.sass";
import loader from "../../images/loader.gif";

export function Loader() {
	return (
		<div id={"Loader"}>
			<img id={"GifLoader"} src={loader} alt={"Loader TV5 Monde Plus"} />
		</div>
	);
}
