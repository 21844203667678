import { accessToken } from "../callApi/accessToken";
import { clientRegistration } from "../callApi/clientRegistration";

export async function getToken() {
	try {
		const client = await clientRegistration();
		const token = await accessToken(client);
		return token;
	} catch (error) {
		throw error;
	}
}
